import "./App.css";

export const App = () => {
    return (
        <div className="App">
            <i className="pi pi-cog pi-spin"></i>
            <h2>Under construction...</h2>
        </div>
    );
};
